import { RangeInput } from '@renderer-ui-library/molecules'
import { TDisplayDefinitionEntryNumber } from '@website-shared-library/displayDefinition/entry/DisplayDefinitionEntryNumber'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { FilterableMachineAttributes } from '@website-shared-library/search/FilterableMachineAttributes'
import { TFilterableMachineAttribute } from '@website-shared-library/search/TFilterableMachineAttribute'
import { useRangeFilterOptions } from 'blocks/SearchResultsBlock/useRangeFilterOptions'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback, useMemo } from 'react'

export type RangeSelectValue = number | undefined

type Props = {
  disabled?: boolean
  config: TDisplayDefinitionEntryNumber
  min: number | undefined
  max: number | undefined
  onChange: (
    machineAttribute: TFilterableMachineAttribute,
    value: {
      min?: RangeSelectValue
      max?: RangeSelectValue
    }
  ) => void
}

export const SearchRangeFilter: React.FC<Props> = React.memo(
  ({ onChange, config, min, max, disabled }) => {
    const { t } = useTranslation(localeNamespace.common)
    const rangeFilterOptions = useRangeFilterOptions(
      config.machineAttribute as TFilterableMachineAttribute,
      config.label
    )

    const handleChange = useCallback(
      (value: { min?: RangeSelectValue; max?: RangeSelectValue }) =>
        onChange(config.machineAttribute as TFilterableMachineAttribute, value),
      [config.machineAttribute, onChange]
    )

    const rangeFilter = useMemo(() => {
      let labels = {
        minLabel: '',
        maxLabel: '',
      }
      switch (config.machineAttribute) {
        case FilterableMachineAttributes.YEAR_OF_PRODUCTION:
          labels = {
            minLabel: t(
              translations.searchDesktopOverlayYearFromLabel
            ).toString(),
            maxLabel: t(
              translations.searchDesktopOverlayYearToLabel
            ).toString(),
          }
          break

        case FilterableMachineAttributes.ENGINE_HP:
          labels = {
            minLabel: t(
              translations.searchDesktopOverlayPowerFromLabel
            ).toString(),
            maxLabel: t(
              translations.searchDesktopOverlayPowerToLabel
            ).toString(),
          }
          break

        case FilterableMachineAttributes.PRICE_NET_EUR:
          labels = {
            minLabel: t(
              translations.searchDesktopOverlayPriceFromLabel
            ).toString(),
            maxLabel: t(
              translations.searchDesktopOverlayPriceToLabel
            ).toString(),
          }
          break
      }

      return {
        ...rangeFilterOptions,
        ...labels,
      }
    }, [config.machineAttribute, rangeFilterOptions, t])

    return (
      <RangeInput
        {...rangeFilter}
        unit={undefined}
        minValue={min}
        maxValue={max}
        noSeparator
        onChange={handleChange}
      />
    )
  }
)

SearchRangeFilter.displayName = 'SearchRangeFilter'
