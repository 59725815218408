import { Close, Search } from '@mui/icons-material'

import { Button } from '@renderer-ui-library/atoms'
import {
  BrandSelectFilter,
  MachineTypeSelectFilter,
  TFilterOption,
} from '@renderer-ui-library/molecules'
import {
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@renderer-ui-library/mui'
import { OverlayColor } from '@renderer-ui-library/mui/base/useCreateTheme'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { TFilterableMachineAttribute } from '@website-shared-library/search/TFilterableMachineAttribute'
import { ModelSelectFilter } from 'blocks/SearchCoverBlock/ModelSelectFilter'
import { RangeSelectValue } from 'blocks/SearchCoverBlock/RangeSelectFilter'
import { sanitizeAppliedRangeFilters } from 'blocks/SearchCoverBlock/sanitizeAppliedRangeFilters'
import { useSearchCoverBlockRangeFilters } from 'blocks/SearchCoverBlock/useSearchCoverBlockRangeFilters'
import { TRangeFiltersMap } from 'blocks/SearchResultsBlock/TRangeFiltersMap'
import {
  aboveMaxSearchResultsLabel,
  maxSearchResults,
} from 'blocks/SearchResultsBlock/maxSearchResults'
import { rangeFiltersMapToSearchFilters } from 'blocks/SearchResultsBlock/rangeFiltersMapToSearchFilters'
import { useSearchResultsRouting } from 'blocks/SearchResultsBlock/useSearchResultsRouting'
import classNames from 'classnames'
import { translations } from 'i18n/translations'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { getSearchResultsCount } from 'services/unauthenticatedBackendClient'
import { fallbackMachineType } from 'utils/constants'

import { SearchModel } from '@internal/blocks/SearchResultsBlock/TSearchParams'
import { searchMachines } from '@internal/services/apiClient'
import { RolloutsContext } from '@internal/utils/rollouts/RolloutsContext'
import { DefaultQueryParams } from '@internal/utils/routing/urls/DefaultQueryParams'
import { UserContext } from '@internal/utils/user/UserContext'
import { SortOrder } from '@website-shared-library/search/SortOrder'
import { createMachineFilterEvent } from 'utils/tracking/createMachineFilterEvent'
import { tracker } from 'utils/tracking/tracker'
import { SearchRangeFilter } from './SearchRangeFilter'
import styles from './searchOverlay.module.scss'

export interface SearchDesktopProps {
  open: boolean
  brand: string | null
  mappedModel: string | null
  machineType: MachineType
  resultsCount: number | null
  onClose: () => void
}

export const SearchDesktopFields: React.FC<SearchDesktopProps> = React.memo(
  (props) => {
    const { t } = useTranslation(localeNamespace.common)
    const [appliedRangeFilters, setAppliedRangeFilters] =
      useState<TRangeFiltersMap>({})
    const [machineType, setMachineType] =
      useState<MachineType>(fallbackMachineType)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [resultsCount, setResultsCount] = useState<number | null>(null)
    const [brands, setBrands] = useState<TFilterOption[]>([])
    const [model, setModel] = useState<SearchModel | null>(null)
    const [abortController, setAbortController] =
      useState<AbortController | null>(null)
    const { query } = useRouter()
    const { ip } = query as DefaultQueryParams

    const { getRangeFilters, rangeFilterAttributes } =
      useSearchCoverBlockRangeFilters()
    const rangeFilters = getRangeFilters(props.machineType)
    const { gotoSearchResults } = useSearchResultsRouting()

    const { newSearchOn } = useContext(RolloutsContext)
    const { user } = useContext(UserContext)

    useEffect(() => {
      if (props.brand) {
        const newBrands = [
          {
            id: props.brand,
            label: props.brand,
          },
        ]
        setBrands(newBrands)
      }

      if (props.mappedModel) {
        const newModel: SearchModel = {
          name: props.mappedModel,
          type: 'mapped',
        }
        setModel(newModel)
      }
    }, [props.brand, props.mappedModel])

    useEffect(() => {
      const sanitizedRangeFilters = sanitizeAppliedRangeFilters(
        rangeFilters,
        appliedRangeFilters
      )

      if (!isEqual(appliedRangeFilters, sanitizedRangeFilters)) {
        setAppliedRangeFilters(sanitizedRangeFilters)
      }
    }, [rangeFilters, appliedRangeFilters])

    const updateSearchResults = useCallback(
      (params: {
        machineType: MachineType
        brands: string[] | null
        appliedRangeFilters: TRangeFiltersMap
        model: SearchModel | null
      }) => {
        if (abortController && !abortController.signal.aborted) {
          abortController.abort()
        }
        setIsLoading(true)

        const newAbortController = new AbortController()
        setAbortController(newAbortController)

        if (newSearchOn) {
          searchMachines({
            search: {
              machineType: params.machineType,
              brands: params.brands ? params.brands : undefined,
              model: params.model ?? undefined,
              filters: rangeFiltersMapToSearchFilters(
                params.appliedRangeFilters
              ),
              sort: {
                by: 'relevance',
                order: SortOrder.Descending,
              },
              limit: 1,
              offset: 0,
            },
            abortController: newAbortController,
            userCountry: user?.country ?? null,
            ip: ip ?? null,
            noCache: false,
            refreshCache: false,
          })
            .then((res) => setResultsCount(res.count))
            .catch(() => setResultsCount(null))
            .finally(() => setIsLoading(false))
        } else {
          getSearchResultsCount(
            {
              machineType: params.machineType,
              brands: params.brands ? params.brands : undefined,
              model: params.model
                ? {
                    name: params.model.name,
                    isMapped: params.model.type !== 'raw',
                  }
                : undefined,
              filters: rangeFiltersMapToSearchFilters(
                params.appliedRangeFilters
              ),
              ip,
            },
            newAbortController
          )
            .then(setResultsCount)
            .catch(() => setResultsCount(null))
            .finally(() => setIsLoading(false))
        }
      },
      [abortController, ip, newSearchOn, user?.country]
    )

    const onChangeMachineType = useCallback(
      async (machineType: MachineType) => {
        if (!machineType) {
          return
        }

        setMachineType(machineType)
        setModel(null)
        setBrands([])
        setIsLoading(true)

        updateSearchResults({
          machineType,
          brands: null,
          model: null,
          appliedRangeFilters: sanitizeAppliedRangeFilters(
            getRangeFilters(machineType),
            appliedRangeFilters
          ),
        })
      },
      [updateSearchResults, getRangeFilters, appliedRangeFilters]
    )

    const onChangeBrand = useCallback(
      (brands: TFilterOption[]) => {
        setModel(null)
        setBrands(brands)

        if (!machineType) {
          return
        }

        updateSearchResults({
          machineType,
          brands: brands.map((brand) => brand.id),
          model: null,
          appliedRangeFilters,
        })
      },
      [updateSearchResults, machineType, appliedRangeFilters]
    )

    const onChangeModel = useCallback(
      (model: SearchModel | null) => {
        setModel(model)
        if (!machineType) {
          return
        }

        updateSearchResults({
          machineType,
          brands: brands.map((brand) => brand.id),
          model,
          appliedRangeFilters,
        })
      },
      [machineType, updateSearchResults, brands, appliedRangeFilters]
    )

    const onChangeRange = useCallback(
      (
        machineAttribute: TFilterableMachineAttribute,
        value: {
          min?: RangeSelectValue
          max?: RangeSelectValue
        }
      ) => {
        const newAppliedRangeFilters: TRangeFiltersMap = {
          ...appliedRangeFilters,
          [machineAttribute]: {
            ...appliedRangeFilters[machineAttribute],
            ...value,
          },
        }

        setAppliedRangeFilters(newAppliedRangeFilters)

        if (!machineType) {
          return
        }

        updateSearchResults({
          machineType,
          brands: brands.map((brand) => brand.id),
          model,
          appliedRangeFilters: newAppliedRangeFilters,
        })
      },
      [updateSearchResults, machineType, brands, model, appliedRangeFilters]
    )

    const handleButtonClick = useCallback(() => {
      if (!machineType) {
        return
      }

      props.onClose()
      tracker.trackEvent(
        createMachineFilterEvent(
          {
            name: 'filter-applied',
            filter_placement: 'header',
            filter_machine_type: machineType,
            filter_model: model ? model.name : undefined,
          },
          {
            filterBrands: brands.map((brand) => brand.id),
            rangeFilters: appliedRangeFilters,
          }
        )
      )

      gotoSearchResults(
        {
          machineType,
          brands: brands.map((brand) => brand.id),
          model: model ?? undefined,
          rangeFilters: appliedRangeFilters,
        },
        false
      )
      setMachineType(fallbackMachineType)
    }, [
      machineType,
      brands,
      model,
      appliedRangeFilters,
      props,
      gotoSearchResults,
    ])

    return (
      <>
        <div
          className={classNames(styles.shim, { [styles.show]: props.open })}
          onClick={props.onClose}
        />
        <div
          className={classNames(styles.searchDesktopWrapper, {
            [styles.open]: props.open,
          })}
        >
          <Container maxWidth='lg'>
            <Grid container spacing={2}>
              <Grid item flex='1'>
                <div className={styles.searchHeaderContent}>
                  <Search />
                  <Typography component='p' variant='subtitle1'>
                    {t(translations.searchCoverBlockButtonTextSearch)}
                  </Typography>
                </div>
              </Grid>

              <Grid item>
                <Close onClick={props.onClose} className={styles.clickable} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={3}>
                <MachineTypeSelectFilter
                  selectedMachineType={machineType}
                  onChange={onChangeMachineType}
                />
              </Grid>
              <Grid item md={3}>
                <BrandSelectFilter
                  selectedMachineType={machineType}
                  selectedBrands={brands}
                  onChange={onChangeBrand}
                  disabled={!machineType}
                />
              </Grid>
              <Grid item md={3}>
                <ModelSelectFilter
                  disabled={brands?.length !== 1 || !props.machineType}
                  onChange={onChangeModel}
                  brands={brands}
                  model={model}
                  colorOverlay={OverlayColor.Bright}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} className={styles.noMargin}>
              <Grid item md={9}>
                <Grid container spacing={2}>
                  {rangeFilters.map((rangeFilter) => (
                    <Grid
                      item
                      key={rangeFilter.machineAttribute}
                      md={rangeFilters.length === 3 ? 4 : 6}
                    >
                      <SearchRangeFilter
                        config={rangeFilter}
                        onChange={onChangeRange}
                        min={
                          appliedRangeFilters[rangeFilter.machineAttribute]?.min
                        }
                        max={
                          appliedRangeFilters[rangeFilter.machineAttribute]?.max
                        }
                        disabled={!machineType}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item md={3}>
                <Button
                  size='large'
                  color='secondary'
                  fullWidth
                  startIcon={
                    isLoading ? (
                      <CircularProgress size={22} color='inherit' />
                    ) : (
                      <Search />
                    )
                  }
                  disabled={!props.machineType}
                  onClick={handleButtonClick}
                >
                  {resultsCount === null || !props.machineType
                    ? t(translations.searchCoverBlockButtonTextSearch)
                    : t(translations.searchCoverBlockButtonText, {
                        count: resultsCount,
                        amount:
                          resultsCount >= maxSearchResults
                            ? aboveMaxSearchResultsLabel
                            : resultsCount,
                      })}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    )
  }
)

SearchDesktopFields.displayName = 'SearchDesktopFields'
